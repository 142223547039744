import React, {
  useState,
  useEffect
} from 'react';

import {
  useDispatch,
  useSelector
} from 'react-redux';

import {
  fetchTask,
  submitTest
  // buildSubmitList,
  // postTask
} from '../actions/Rating.action';

import '../styles/index.css';
import { BiArrowBack } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useMediaQuery } from 'react-responsive';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Container from 'react-bootstrap/Container';
import ResponsiveFooter from '../components/ResponsiveFooter/ResponsiveFooter';


function TaskScreen(props) {
  // const [taskAnswers, setTaskAnswers] = useState([]);

  const [show, setShow] = useState(false);
  const [testData, setTestData] = useState([]);

  const dispatch = useDispatch();
  const task_data = useSelector((state) => state.rating.fetch_task);

  const email = useSelector((state) => state.rating.email)
  // const userId = useSelector((state) => state.rating.user_id);
  // const buildSubmitListData = useSelector((state) => state.rating.build_submit_list);

  useEffect(() => {
    dispatch(fetchTask())
  }, [])

  // const answerObject = {};
  // let correctAnswerNumber = 0;
  const optionClicked = (id, isCorrect) => {
    // Commented out functionality related to answerObject
    // answerObject[id] = isCorrect
    // let answerValues = Object.values(answerObject)
    // let trueCount = 0
    // for (let x = 0; x < answerValues.length; x++) {
    //   if (answerValues[x] == true) {
    //     trueCount += 1
    //   }
    // }
    // correctAnswerNumber = trueCount;
  }

  useEffect(() => {
    setTestData(task_data);
  }, [task_data]);
  const handleMCQAnswer = (questionId, optionId) => {
    const updatedTestData = [...testData];
    const questionIndex = updatedTestData.findIndex((q) => q.id === questionId);

    if (questionIndex !== -1) {
      updatedTestData[questionIndex].answer = optionId;
    }

    setTestData(updatedTestData);

  };

  const handleTaskAnswer = (event, questionId) => {
    const updatedTestData = [...testData];
    const questionIndex = updatedTestData.findIndex((q) => q.id === questionId);

    if (questionIndex !== -1) {
      updatedTestData[questionIndex].answer = event.target.value;
    }

    setTestData(updatedTestData);
  };

  const handleSubmit = () => {
    // console.log("handleSubmit() function is called ")

    dispatch(submitTest(email, testData));

    // Show the modal or handle other UI actions
    handleShow();
  };

  const mobile = useMediaQuery({ query: '(max-width: 640px)' })

  // const handleTaskAnswer = async (event, id) => {
  //   setTaskAnswer(event.target.value)
  //   dispatch(buildSubmitList(id, event.target.value))
  // };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Container className='taskscreen-container'>
      <div className="back-button-box">
        <Link className='bk-btn' exact to="/">
          <BiArrowBack style={{ fontSize: 25 }} />
        </Link>
      </div>
      {/* <Breadcrumbs aria-label="breadcrumb">
        <Link
          color="inherit"
          exact to="/test"
        >
          Tasks
        </Link>
        <Link
          color="inherit"
          exact to="/jobportalscreen"
        >
          Job Portal
        </Link>
        <Link
          color="inherit"
          exact to="/jobportalscreen"
        >
          Test
        </Link>
      </Breadcrumbs> */}
      <div className='test-title-div'>
        <div className='title-line-div'>
          <h6 className='title-text'>Task Screen</h6>
        </div>
        <hr className='title-div-line'></hr>
      </div>
      <div className='description-div'>
        <h5 className='description-text1'>Test Your Knowledge</h5>
        <h6 className='description-text2'>For the questions below, please choose the best option to complete the sentence or conversation.</h6>
      </div>
      {
        task_data.map((item, index) => (
          <div className='question-div'>
            <div className='question-title-div'>
              <div className='question-index'>
                <h6 className='question-index-text'>{index + 1}</h6>
              </div>
              <h6 className='question-title'>{item.title}</h6>
            </div>
            {
              item.is_mcq == true ?
                <div className="option-div">
                  {
                    item.options.map((value) => (
                      <div className='options'>
                        <label for={value.id}>
                          <div
                            onClick={() => handleMCQAnswer(item.id, value.id)}
                            className='option'
                          >
                            <input
                              className='task-radio'
                              name={item.id}
                              id={value.id}
                              type="radio"
                            />
                            <h6
                              className='option-text'
                              name={item.id}
                            >
                              {value.option_value}
                            </h6>
                          </div>
                        </label>
                      </div>
                    ))
                  }
                </div>
                :
                <div>
                  {
                    mobile ?
                      <textarea
                        className="textarea"
                        name="input"
                        rows="5"
                        cols="26"
                        onChange={(e) => handleTaskAnswer(e, item.id)}
                      />
                      :
                      <textarea
                        className="textarea"
                        name="input"
                        rows="5"
                        cols="29"
                        onChange={(e) => handleTaskAnswer(e, item.id)}
                      />
                  }
                </div>
            }
          </div>
        ))
      }
      <div className='sub-btn-div'>
        <button
          className='taskscreen-submit-btn'
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
      <div>
        <Modal
          className='modal'
          show={show}
          onHide={handleClose}
          animation={true}
        >
          <Modal.Header
            className="task-modal-header"
            closeButton
          >
          </Modal.Header>
          <Modal.Body>
            <h6 className="task-modal-content modal-content1">Well done, our AI Trainer will reach you shortly !</h6>
          </Modal.Body>
          <Modal.Footer className="modal-content modal-footer">
            <button className="modal-btn ml-2 mr-2">
              <Link
                exact to="/"
                className='a'
              >
                Explore Jobs
              </Link>
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <ResponsiveFooter />
    </Container>
  )
}

export default TaskScreen;
