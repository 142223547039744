import React from 'react';
import ReactMarkdown from 'react-markdown';

const MarkdownComponent = ({ description, result }) => {
    return (
        <div>
            <div className="description">
                <ReactMarkdown>{description}</ReactMarkdown>
            </div>
        </div>
    );
};

export default MarkdownComponent;
