import React, { useState } from 'react';
import "./job_portal.css";

import Container from 'react-bootstrap/Container';
import DiscordModal from '../DiscordModal/DiscordModal';

const CompanyList = ({ companies }) => {
    const [discordModalVisible, setDiscordModalVisible] = useState(false);
    const [opportunity, setOpportunity] = useState('Terafac Live');

    const openDiscordModal = (status, name) => {
        if (status.includes('Coming')){
            setDiscordModalVisible(false);
        }
        else{
            setDiscordModalVisible(true);
            setOpportunity(name)
        }
        
    }

    const closeDiscordModal = () => {
        setDiscordModalVisible(false);
    }


    return (
        <Container fluid="xl" className="languages-main-div">
            {
                companies.map((item, index) => (
                    <div
                        to="/taskscreen"
                        className='a'
                        onClick={() => openDiscordModal(item.type, item.language)}
                        key={index}
                    >
                        <div className='language-box'>
                            <div className='language-image-mobile-div'>
                                <img className="language-image-mobile" src={item.image} />
                            </div>
                            <h6 style={{ color: "black", fontSize: 12, textAlign:'center' }}>{item.language}</h6>
                            <h5 style={{ color: "black", fontSize: 12 }}>{item.type}</h5>
                        </div>
                    </div>
                ))
            }

            {discordModalVisible && (
                <DiscordModal show={discordModalVisible} handleClose={closeDiscordModal} name={opportunity} />
            )}
        </Container>
    );
};

export default CompanyList;



