import data from '../config/data.json'

export const postEmail = email => async (dispatch, getState) => {
  let user_email = email
  if (email == null || email == undefined || email == '') {
    user_email = getState().rating.user_email
  }
  // console.log("Rating.action.js postEmail user-email", user_email)
  try {
    //     const url = "https://api.repetition.live/api/v1/web-user/"
    //     const response = await fetch(url, {
    //         method: "POST",
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify({
    //             email: user_email
    //         })
    //     })

    //     const emailResponse = await response.json();
    // if ("status" in response && response.status < 400) {

    // }
    dispatch({
      type: 'POST_EMAIL',
      payload: { user_email }
    })
  } catch (err) {
    // console.log("Action.js postEmail", err.message)
  }
}

export const fetchTask = () => async (dispatch, getState) => {
  // console.log("fetchTask function called");
  try {
    // Import the JSON data from data.json file
    const task = data.get_task

    dispatch({
      type: 'FETCH_TASK',
      payload: task
    })

    // console.log("fetchTask function successfully completed", task);
  } catch (err) {
    // console.log("Error while fetching data from data.json:", err.message);
  }
}

// export const fetchJobs = () => async (dispatch, getState) => {
//     try {
//         const url = "https://api.repetition.live/api/v1/get-jobs/"
//         const response = await fetch(url, {
//             method: "GET",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json"
//             }
//         })

//         const jobs = await response.json();
//         if ("status" in response && response.status < 400) {
//             dispatch({
//                 type: "FETCH_JOBS",
//                 payload: jobs
//             })
//         }
//     } catch (err) {
//         // console.log("Action.js fetchJobs()", err.message)
//     }
// }

export const fetchJobs = () => async (dispatch, getState) => {
  try {
    // Import the JSON data from data.json file
    const jobs = data.get_jobs

    dispatch({
      type: 'FETCH_JOBS',
      payload: jobs
    })

    // // console.log("fetchJobs function successfully completed", jobs);
  } catch (err) {
    // console.log("Error while fetching data from data.json:", err.message);
  }
}

export const filteredJobData = filter => async (dispatch, getState) => {
  try {
    const jobData = getState().rating.fetch_jobs
    dispatch({
      type: 'FILTERED_JOB_DATA',
      payload: { filter, jobData }
    })
  } catch (err) {
    // console.log("Rating.action.js filteredJobData", err.message)
  }
}

export const fetchSpecificJob = () => async (dispatch, getState) => {
  try {
    const specificJob = data.specific_job
    dispatch({
      type: 'SPECIFIC_JOB',
      payload: specificJob
    })
  } catch (err) {
    // console.log("Rating.action.js filteredJobData", err.message)
  }
}

export const searchedJobData = filter => async (dispatch, getState) => {
  try {
    const jobData = getState().rating.fetch_jobs

    // console.log(" searched job data : ",filter)
    dispatch({
      type: 'SEARCHED_JOB_DATA',
      payload: { filter, jobData }
    })
  } catch (err) {
    // console.log("Rating.action.js searchedJobData", err.message)
  }
}

export const buildSubmitList = (id, answer) => async (dispatch, getState) => {
  // console.log("Rating.action.js buildSubmitList user_id", getState().rating.user_id)
  try {
    dispatch({
      type: 'BUILD_SUBMIT_LIST',
      payload: { id, answer }
    })
  } catch (err) {
    // console.log("Rating.action.js buildSubmitList", err.message)
  }
}

export const postTask = () => async (dispatch, getState) => {
  const buildSubmitList = getState().rating.build_submit_list
  const user = getState().rating.user_id
  const totalSubmitList = []
  for (let x in buildSubmitList) {
    if (typeof buildSubmitList[x] == 'number') {
      totalSubmitList.push({
        user: user,
        task: +x,
        answer: '',
        options: [
          {
            option: buildSubmitList[x]
          }
        ],
        links: []
      })
    } else {
      totalSubmitList.push({
        user: user,
        task: +x,
        answer: buildSubmitList[x],
        options: [],
        links: []
      })
    }
  }

  // console.log("JSON.stringify", JSON.stringify(totalSubmitList))

  const url = 'https://api.repetition.live/api/v1/submit-task/'
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(totalSubmitList)
  })

  const submitResponse = await response.json()
}

export const fetchEnglishTest = () => async (dispatch, getState) => {
  try {
    // console.log("fetchEnglishTest function called");

    // Import the JSON data from data.json file
    const englishTest = data.english_test // assuming 'english_test' is the key in data.json

    dispatch({
      type: 'ENGLISH_TEST',
      payload: englishTest
    })

    // console.log("fetchEnglishTest function successfully completed", englishTest);
  } catch (err) {
    // console.log("Error while fetching English test data from data.json:", err.message);
  }
}

export const englishTestBuildSubmitList =
  (id, answer) => async (dispatch, getState) => {
    // console.log("Rating.action.js englishTestBuildSubmitList user_id", getState().rating.user_id)
    try {
      dispatch({
        type: 'ENGLISH_TEST_BUILD_SUBMIT_LIST',
        payload: { id, answer }
      })
    } catch (err) {
      // console.log("Rating.action.js englishTestBuildSubmitList", err.message)
    }
  }

export const postEnglishTest = () => async (dispatch, getState) => {
  postObjectToDiscord()

  const buildSubmitList = getState().rating.english_test_build_submit_list
  // console.log("  buildSumbitList : ", buildSubmitList)

  const user = getState().rating.user_id
  // console.log("user : ", user)
  const totalSubmitList = []
  // console.log("totalSumbitList : ", totalSubmitList)
  for (let x in buildSubmitList) {
    if (typeof buildSubmitList[x] == 'number') {
      totalSubmitList.push({
        user: user,
        task: +x,
        answer: '',
        options: [
          {
            option: buildSubmitList[x]
          }
        ],
        links: []
      })
    } else {
      totalSubmitList.push({
        user: user,
        task: +x,
        answer: buildSubmitList[x],
        options: [],
        links: []
      })
    }
  }

  // console.log("JSON.stringify data ", JSON.stringify(totalSubmitList))
  if (totalSubmitList.length > 0) {
    // Check if totalSubmitList is not empty
    postObjectToDiscord()
  }

  const url = 'https://api.repetition.live/api/v1/submit-task/'
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(totalSubmitList)
  })

  const submitResponse = await response.json()
}

// export const fetchAptitudeTest = () => async (dispatch, getState) => {
//     try {
//         const url = "https://api.repetition.live/api/v1/get-task/?exam_type=aptitude test";
//         const response = await fetch(url, {
//             method: "GET",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json"
//             }
//         })

//         const englishTestResponse = await response.json();
//         if ("status" in response && response.status < 400) {
//             dispatch({
//                 type: "APTITUDE_TEST",
//                 payload: englishTestResponse
//             })
//         }
//     } catch (err) {
//         // console.log("fetchEnglishTest()")
//     }
// }

export const fetchAptitudeTest = () => async (dispatch, getState) => {
  try {
    // Import the JSON data from data.json file
    const aptitudeTest = data.get_task

    dispatch({
      type: 'APTITUDE_TEST',
      payload: aptitudeTest
    })

    // console.log("fetchAptitudeTest function successfully completed", aptitudeTest);
  } catch (err) {
    // console.log("Error while fetching data from data.json:", err.message);
  }
}

export const aptitudeTestBuildSubmitList =
  (id, answer) => async (dispatch, getState) => {
    // console.log("Rating.action.js aptitudeTestBuildSubmitList user_id", getState().rating.user_id)
    try {
      dispatch({
        type: 'APTITUDE_TEST_BUILD_SUBMIT_LIST',
        payload: { id, answer }
      })
    } catch (err) {
      // console.log("Rating.action.js aptitudeTestBuildSubmitList", err.message)
    }
  }

export const postAptitudeTest = () => async (dispatch, getState) => {
  const buildSubmitList = getState().rating.aptitude_test_build_submit_list
  const user = getState().rating.user_id
  const totalSubmitList = []
  for (let x in buildSubmitList) {
    if (typeof buildSubmitList[x] == 'number') {
      totalSubmitList.push({
        user: user,
        task: +x,
        answer: '',
        options: [
          {
            option: buildSubmitList[x]
          }
        ],
        links: []
      })
    } else {
      totalSubmitList.push({
        user: user,
        task: +x,
        answer: buildSubmitList[x],
        options: [],
        links: []
      })
    }
  }

  // console.log("JSON.stringify", JSON.stringify(totalSubmitList))

  const url = 'https://api.repetition.live/api/v1/submit-task/'
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(totalSubmitList)
  })

  const submitResponse = await response.json()
  if ('status' in response && response.states < 400) {
    // console.log("Rating.action.js postAptitudeTest() successfully submitted")
  }
}

export const fetchJobFilters = () => async (dispatch, getState) => {
  try {
    const url = 'https://api.repetition.live/api/v1/get-jobs-filters'
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    const jobFiltersResponse = await response.json()
    if ('status' in response && response.status < 400) {
      dispatch({
        type: 'FETCH_JOB_FILTERS',
        payload: jobFiltersResponse
      })
    }
  } catch (err) {
    // console.log("Rating.action.js fetchJobFilters", err.message)
  }
}

export const fetchLanguages = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: 'LANGUAGES',
      payload: data.programming_languages
    })
  } catch (err) {
    // console.log("Rating.action.js fetchLanguages()", err.message)
  }
}

export const fetchCompanies = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: 'COMPANIES',
      payload: data.company
    })
  } catch (err) {
    // console.log("Rating.action.js fetchLanguages()", err.message)
  }
}

export const fetchLanguagesTask = language => async (dispatch, getState) => {
  try {
    const url =
      'https://api.repetition.live/api/v1/get-task/?exam_type=' + language
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    const languageTask = await response.json()
    if ('status' in response && response.status < 400) {
      dispatch({
        type: 'LANGUAGE_TASK',
        payload: languageTask
      })
    }
  } catch (err) {
    // console.log("fetchLanguageTask()", err.message)
  }
}

export const saveJobId = id => async (dispatch, getState) => {
  // console.log("Rating.action.js saveJobId id", id)
  try {
    dispatch({
      type: 'SAVE_JOB_ID',
      payload: id
    })
  } catch (err) {
    // console.log("Rating.action.js saveJobId", err.message)
  }
}

export const saveJobName = job => async (dispatch, getState) => {
  // console.log("Rating.action.js saveJobId id", job)
  try {
    dispatch({
      type: 'SAVE_JOB_NAME',
      payload: job
    })
  } catch (err) {
    // console.log("Rating.action.js saveJobId", err.message)
  }
}

export const saveUserId = id => async (dispatch, getState) => {
  // console.log("Rating.action.js saveJobId id", id)
  try {
    dispatch({
      type: 'SAVE_JOB_ID',
      payload: id
    })
  } catch (err) {
    // console.log("Rating.action.js saveJobId", err.message)
  }
}

export const saveName = name => async (dispatch, getState) => {
  // console.log("Rating.action.js saveName name : ", name)
  try {
    dispatch({
      type: 'SAVE_USER_NAME',
      payload: name
    })
  } catch (err) {
    // console.log("Rating.action.js saveName  : ", err.message)
  }
}

export const saveUserInfo =
  (name, email, number, yourMessage, experience, job) =>
  async (dispatch, getState) => {
    let userInfo = {
      name,
      email,
      number,
      yourMessage,
      experience,
      job
    }
    // console.log("saveUserInfo userInfo", userInfo)
    try {
      dispatch({
        type: 'SAVE_USER_INFO',
        payload: { name, email, number, yourMessage, experience, apiResponse }
      })
      const url = 'https://api.repetition.live/api/v1/web-user/'
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          experience: experience,
          name: name,
          number: number,
          yourMessage: yourMessage,
          job: job
        })
      })

      const apiResponse = await response.json()
      // console.log("Rating.action.js saveUserInfo() response", apiResponse)
      if ('status' in response && response.status < 400) {
        dispatch({
          type: 'SAVE_USER_INFO',
          payload: { name, email, number, yourMessage, experience, apiResponse }
        })
      }
    } catch (err) {
      // console.log("Rating.action.js saveUserInfo", err.message)
    }
  }

export const postInformationToDiscord =
  (name, email, number, yourMessage, level, job) =>
  async (dispatch, getState) => {
    let userInfo = {
      name,
      email,
      number,
      yourMessage,
      level,
      job
    }
    // console.log("content variable inside postSummaryToDiscord()  : ", userInfo);
    // alert(userInfo)
    try {
      const pseudoname = 'Dashboard'
      const url =
        'https://discord.com/api/webhooks/1162713198533820467/bgCCcBUBwJxdDGs46LD0MTXH_9rZRrmwy7O821c3YcCFKVSiqIrGl6jjyY1ZLCLZaGdX'
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: pseudoname,
          content: JSON.stringify(userInfo), // Convert userInfo to a JSON string
          tts: false,
          color: 'white'
        })
      })
      const discordResponse = await response.text()
      // console.log("postInformationToDiscord", discordResponse);

      dispatch({
        type: 'SAVE_USER_EMAIL',
        payload: email
      })
    } catch (err) {
      // console.log("postInformationToDiscord", err.message);
    }
  }

export const postEmailToDiscord = email => async (dispatch, getState) => {
  let userInfo = {
    email
  }
  console.log("Rating.action.js postEmailToDiscord() function userInfo", userInfo)
  // alert(userInfo)
  try {
    const pseudoname = 'Dashboard'
    const url =
      'https://discord.com/api/webhooks/1162713198533820467/bgCCcBUBwJxdDGs46LD0MTXH_9rZRrmwy7O821c3YcCFKVSiqIrGl6jjyY1ZLCLZaGdX'
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: pseudoname,
        content: JSON.stringify(userInfo), // Convert userInfo to a JSON string
        tts: false,
        color: 'white'
      })
    })
    const discordResponse = await response.text()

    dispatch({
      type: 'SAVE_USER_EMAIL',
      payload: email
    })
  } catch (err) {
    console.log("postInformationToDiscord", err.message);
  }
}

export const postObjectToDiscord = data => async (dispatch, getState) => {
  const buildSubmitList = getState().rating.english_test_build_submit_list
  // console.log("  buildSumbitList : ", buildSubmitList)

  const user = getState().rating.user_id
  // console.log("user : ", user)
  const totalSubmitList = []
  // console.log("totalSumbitList : ", totalSubmitList)
  for (let x in buildSubmitList) {
    if (typeof buildSubmitList[x] == 'number') {
      totalSubmitList.push({
        user: user,
        task: +x,
        answer: '',
        options: [
          {
            option: buildSubmitList[x]
          }
        ],
        links: []
      })
    } else {
      totalSubmitList.push({
        user: user,
        task: +x,
        answer: buildSubmitList[x],
        options: [],
        links: []
      })
    }
  }

  // console.log("JSON.stringify data ", JSON.stringify(totalSubmitList))

  try {
    const pseudoname = 'Dashboard'
    const url =
      'https://discord.com/api/webhooks/1162713198533820467/bgCCcBUBwJxdDGs46LD0MTXH_9rZRrmwy7O821c3YcCFKVSiqIrGl6jjyY1ZLCLZaGdX'
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: pseudoname,
        content: JSON.stringify(data), // Convert the provided data to a JSON string
        tts: false,
        color: 'white'
      })
    })
    const discordResponse = await response.text()
    // console.log("postObjectToDiscord", discordResponse);
  } catch (err) {
    // console.log("postObjectToDiscord", err.message);
  }
}
// export const fetchLanguagesTask = (language) => async (dispatch, getState) => {
//     try {
//         // Replace this with the correct key to access the language data in data.json.
//         const languageDataKey = language;

//         // Import the JSON data from data.json file
//         const languagesTask =  data.get;

//         // Check if the language data exists in the JSON object.
//         if (languageDataKey in data) {
//             const languageTask = data[languageDataKey];

//             dispatch({
//                 type: "LANGUAGE_TASK",
//                 payload: languageTask
//             });

//             // console.log("fetchLanguagesTask function successfully completed", languageTask);
//         } else {
//             console.error(`Language "${language}" data not found in data.json`);
//         }
//     } catch (err) {
//         // console.log("Error while fetching data from data.json:", err.message);
//     }
// };

export const submitTest = (email, testData) => {
  return dispatch => {
    // Convert the testData to a JSON structure for posting to Discord
    const jsonData = testData.map(item => {
      if (item.is_mcq) {
        let answer = ''
        if (Array.isArray(item.answer)) {
          // For MCQ questions with multiple selected answers
          const selectedOptions = item.options
            .filter(option => item.answer.includes(option.id))
            .map(option => option.option_value)
          answer = selectedOptions.join(', ') // Join selected options into a string
        } else if (item.answer !== undefined) {
          // For MCQ questions with a single selected answer
          const selectedOption = item.options.find(
            option => option.id === item.answer
          )
          answer = selectedOption ? selectedOption.option_value : ''
        }
        return {
          type: 'MCQ',
          question: item.title,
          options: item.options.map(option => option.option_value),
          submitted_answer: answer
        }
      } else {
        // For subjective questions, keep the answer as is
        return {
          type: 'Subjective',
          question: item.title,
          options: [],
          submitted_answer: item.answer || '' // Default to an empty string if no answer
        }
      }
    })

    // Post the JSON data to Discord
    // console.log(" json data on screen ", jsonData);
    const modifiedJsonData = {
      email: email,
      questions: jsonData
    }
    dispatch(postObjectToDiscord(modifiedJsonData))
  }
}

export const saveEmailId = popup_email => async (dispatch, getState) => {
  console.log('Rating.action.js saveEmailId', popup_email)
  try {
    dispatch({
      type: 'SAVE_EMAIL_ID',
      payload: popup_email
    })
  } catch (err) {
    console.log('HomePage.action.js saveEmailId()', err.message)
  }
}
