import React, { useState } from 'react'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import EmailPopup from '../EmailPopup/EmailPopup'
import { useDispatch, useSelector } from 'react-redux'

const ResponsiveHeader = () => {
  const [showEmailPopup, setShowEmailPopup] = useState(false)
  const [popupContent, setPopupContent] = useState({ href: '', buttonText: '' })
  const savedEmail = useSelector(state => state.rating.popup_email)

  const handleEmailPopupClose = () => {
    setShowEmailPopup(false)
  }

  const handleEmailPopupOpen = event => {
    console.log('Navbar handleEmailPopupOpen savedEmail', savedEmail)

    if (savedEmail == '') {
      event.preventDefault()
      const buttonText = event.target.innerText
      const href = event.target.getAttribute('href')
      setPopupContent({ href, buttonText })
      setShowEmailPopup(true)
    } else {
      console.log('Email is already saved')
      event.preventDefault()
      const buttonText = event.target.innerText
      const href = event.target.getAttribute('href')
      setPopupContent({ href, buttonText })
      setShowEmailPopup(true)
    }
  }

  return (
    <Navbar expand='lg' className='bg-body-tertiary'>
      <Container>
        <Navbar.Brand href='https://terafac.live'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img
              src={require('../../assets/images/terafac-logo.png')}
              style={{ height: 60, width: 60, marginRight: 5 }}
            />
            <div className='mt-1'>
              <span className='terafac-live'>
                Terafac <span style={{ fontWeight: 400 }}>Live</span>
              </span>
              {/* <h1 className='terafac-live'>
                Terafac <span style={{ fontWeight: 400 }}>Live</span>
              </h1> */}
              {/*<span 
                   			style={{ 
                   				fontSize: 36, 
                   				fontWeight: '400'
                   			}}>live
           				</span>*/}
            </div>
          </div>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls='basic-navbar-nav' />

        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ms-auto'>
            {/*<Nav.Link href='https://terafac.live' style={{ fontSize: 20 }}>
              Home
            </Nav.Link>*/}
            <Nav.Link
              onClick={handleEmailPopupOpen}
              href='https://discord.gg/8VYKYCQSA2'
              style={{ fontSize: 20 }}
            >
              Get Early Access
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
      {showEmailPopup && (
        <EmailPopup
          buttonName={popupContent.buttonText}
          hrefUrl={popupContent.href}
          onClose={handleEmailPopupClose}
        />
      )}
    </Navbar>
  )
}

export default ResponsiveHeader
