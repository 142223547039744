import React, { useState } from 'react';
import './modal.css'

import { FiX } from "react-icons/fi";
import { BsCheck2Circle } from 'react-icons/bs';
import { colors } from '../../config/Theme';

import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { saveEmailId, postEmailToDiscord } from '../../actions/Rating.action'

const SubmitModal = ({ name, email, show, handleClose }) => {

    function isEmailValid(email) {
      // Regular expression for basic email validation
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      return emailPattern.test(email);
    }

    const dispatch = useDispatch()

    const savedEmail = useSelector(state => state.rating.popup_email)
    const emailValue = savedEmail == '' ? email : savedEmail

    function encodeEmailToBase64 (email) {
      const encodedEmail = btoa(email)
      return encodedEmail
    }

    function handleDiscord (event) {
        const encodedEmail = encodeEmailToBase64(emailValue)
        dispatch(postEmailToDiscord(emailValue))
        
        if (emailValue == "") {
            alert("Please enter your email.")
            event.preventDefault()
        } else {
            dispatch(saveEmailId(emailValue))
            event.preventDefault(); 
            const baseUrl = event.currentTarget.getAttribute('href');
            const fullUrl = `${baseUrl}?email=${encodedEmail}`;
            window.location.href = fullUrl;
        }
      }

    return (
        <Modal
            className='modal'
            show={show}
            onHide={handleClose}
            animation={true}
        >

            <Modal.Header>
                <FiX onClick={() => handleClose(isEmailValid(email))} className='cross-modal' />
            </Modal.Header>
            {isEmailValid(email) ? (<Modal.Body>
                <h6 className="task-modal-content modal-content1" style={{ textAlign: 'center' }}>{name ? name: email}, begin your job experience !</h6>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginTop: -10 }}>
                    <a
                        // exact to="/aptitudescreen"
                        onClick={handleDiscord}
                        href='https://discord.gg/Y8GRcfQNme'
                        className='modal-btn a'
                    >
                        Join Our Discord Channel
                    </a>

                </div>
            </Modal.Body>) : (<Modal.Body>
                <h6 className="task-modal-content modal-content1" style={{ textAlign: 'center' }}>Please share a real email !</h6>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginTop: -10 }}>
                    <a
                        onClick={() => handleClose(false)}
                        className='modal-btn a'
                        style={{color:'white'}}
                    >
                        Go Back
                    </a>

                </div>
            </Modal.Body>
            )
            }
            {/*<Modal.Body className='test-modal-footer'>
                <BsCheck2Circle style={{
                    fontSize: 20,
                    color: colors.persianGreen,
                    marginRight: 5
                }}
                />
                <h6 className='modal-footer-text'>We have also sent you an onboarding email !</h6>
            </Modal.Body>*/}
        </Modal>
        );
    };

export default SubmitModal;

 