import React, { useState, useEffect } from 'react';

function TruncatedDescription({ description, length }) {
    const [truncated, setTruncated] = useState('');

    useEffect(() => {
        if (description) {
            const words = description.slice(0,length).split(' ');
            words.pop()
            const truncatedText = words.join(' ');

            if (description.length > length) {
                setTruncated(truncatedText + ' ...');
            } else {
                setTruncated(truncatedText);
            }
        }
    }, [description]);

    return <p>{truncated}</p>;
}

export default TruncatedDescription;
