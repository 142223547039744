import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import './discord_modal.css'
import { FiX } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { saveEmailId, postEmailToDiscord } from '../../actions/Rating.action'

const DiscordModal = ({ show, handleClose, name }) => {
  const savedEmail = useSelector(state => state.rating.popup_email)

  const dispatch = useDispatch()

  const [email, setEmail] = useState("")

  function handleEmail (event) {
    setEmail(event.target.value)
  }

  const emailValue = savedEmail == '' ? email : savedEmail

  function encodeEmailToBase64 (email) {
    const encodedEmail = btoa(email)
    return encodedEmail
  }

  function handleDiscord (event) {
    const encodedEmail = encodeEmailToBase64(emailValue)
    dispatch(postEmailToDiscord(emailValue))
    
    if (emailValue == "") {
        alert("Please enter your email.")
        event.preventDefault()
    } else {
        dispatch(saveEmailId(emailValue))
        event.preventDefault(); 
        const baseUrl = event.currentTarget.getAttribute('href');
        const fullUrl = `${baseUrl}?email=${encodedEmail}`;
        window.location.href = fullUrl;
    }
  }


  return (
    <Modal className='modal' show={show} onHide={handleClose} animation={true}>
      <Modal.Header>
        <div style={{ flex: 1, width: '100%', textAlign: 'center' }}>
          {"Let's get started"}
        </div>
        <FiX onClick={() => handleClose()} className='cross-modal mt-20' />
      </Modal.Header>
      <Modal.Body>
        <p className='task-modal-content modal-content1'>
          {`Join our server, where our AI team will work with you to find you the best fit internship opportunity for you at ${name} and similar companies.`}
        </p>
        <input
          className='email-phone-input'
          placeholder='Email'
          typemail-phone='email'
          value={emailValue}
          onChange={handleEmail}
        />
      </Modal.Body>
      <Modal.Footer className='modal-content modal-footer'>
        <button className='modal-btn ml-2 mr-2'>
          <a onClick={handleDiscord} href='https://discord.gg/8VYKYCQSA2' className='a'>
            Join Terafac Live Discord
          </a>
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default DiscordModal
