import React, { useState } from 'react'
import './emailpopup.css'
import { useDispatch, useSelector } from 'react-redux'
import { saveEmailId, postEmailToDiscord } from '../../actions/Rating.action';

const Popup = ({ buttonName, hrefUrl, onClose }) => {

  const savedEmail = useSelector((state) => state.rating.popup_email)

  const dispatch = useDispatch()

  const [email, setEmail] = useState(savedEmail)

  function handleEmail (event) {
    setEmail(event.target.value)
  }

  function encodeEmailToBase64 (email) {
    const encodedEmail = btoa(email)
    return encodedEmail
  }

  function handleButton (e) {
    dispatch(saveEmailId(email))
    // alert(email)
    dispatch(postEmailToDiscord(email))

    onClose()

    const encodedEmail = encodeEmailToBase64(email)
    e.preventDefault(); 
    const baseUrl = e.currentTarget.getAttribute('href');
    const fullUrl = `${baseUrl}?email=${encodedEmail}`;
    window.location.href = fullUrl;
  }

  return (
    <div className='email-popup'>
      {/* <h4>{JSON.stringify(savedEmail)}</h4> */}
      <button className='email-close-button' onClick={onClose}>
        &times;
      </button>
      <div className='email-popup-content-div'>
        <div className='email'>
          <label className='email-first-name-label'></label>
          <input
            className='popup-input'
            placeholder='Email'
            typemail-phone='email'
            value={email}
            onChange={handleEmail}
          />
        </div>
        <a
          type='button'
          onClick={handleButton}
          className='redirect-btn'
          href={hrefUrl}
        >
          {buttonName}
        </a>
      </div>
    </div>
  )
}

export default Popup
