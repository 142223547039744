import React from 'react';
import './job_portal.css'

import { useMediaQuery } from 'react-responsive';
import { Container, Row, Col} from 'react-bootstrap';
import { colors } from '../../config/Theme';
import { Link } from 'react-router-dom';
import TruncatedDescription from '../truncatedText/TruncatedText';

function JobList({ jobs, handleClick, showModal }) {

  const mobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <Container fluid="xl" style={{ marginTop: '20px' }}>
        <Row>
        {jobs.map((item) => (
          <Col key={item.id} xs={12} md={6}>
                <div key={item.id} style={{ height: '400px', borderRadius:'20px' }}>
                  <Link
                    style={{ color: 'white', textDecoration: 'none' }}
                    className=' enter-button a'
                    to={item.enter !== "Enter" ? "/" : '/description'}
                    onClick={() => {
                      if ( item.enter !== "Enter"){
                        handleClick(item.id, item.job)
                        showModal()
                      }
                      else{
                        handleClick(item.id, item.job)  
                      }
                    }}
                  >
                    
                    <div className="card-container">
                      <img variant="top" src={item.image} alt="Background" className='card-image'/>
                      <div className="card-body">
                        <div className='bottom-overlay-section' >
                          <h4 style={{ fontWeight: '600' }}>{item.job}</h4>
                          <TruncatedDescription description={item.description} length={mobile ? 50 : 100} />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
          </Col>
        ))}
       </Row>
       
       <div className="job-loader-box"> 
          <button onClick={() => showModal()} className="job-loader-btn">More jobs coming soon...</button>
       </div>
    
    </Container>
  );
}

export default JobList;



