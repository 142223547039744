import React, { useState } from 'react'
import './hero.css'
import EmailPopup from '../EmailPopup/EmailPopup'
import { useDispatch, useSelector } from 'react-redux'

const HeroComponent = ({ internship }) => {
  const [showEmailPopup, setShowEmailPopup] = useState(false)
  const [popupContent, setPopupContent] = useState({ href: '', buttonText: '' })
  const savedEmail = useSelector(state => state.rating.popup_email)

  const handleEmailPopupOpen = event => {
    console.log('Navbar handleEmailPopupOpen savedEmail', savedEmail)

    if (savedEmail == '') {
      event.preventDefault()
      const buttonText = event.target.innerText
      const href = event.target.getAttribute('href')
      setPopupContent({ href, buttonText })
      setShowEmailPopup(true)
    } else {
      console.log('Email is already saved')
      event.preventDefault()
      const buttonText = event.target.innerText
      const href = event.target.getAttribute('href')
      setPopupContent({ href, buttonText })
      setShowEmailPopup(true)
    }
  }

  const handleEmailPopupClose = () => {
    setShowEmailPopup(false)
  }

  return (
    <section id='hero' className='d-flex align-items-center'>
      <div
        className='container container-div'
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div className='col-xl-12 margin-top'>
          <h1 style={{ textAlign: 'center' }}>Welcome to JEX </h1>
          <h3 style={{ textAlign: 'center' }}>A Virtual Job Experience Room</h3>
          <h2 style={{ textAlign: 'center' }}>
            Choose from the job below to begin your job experience journey{' '}
          </h2>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <a
              onClick={handleEmailPopupOpen}
              href='https://discord.gg/8VYKYCQSA2'
              className='explore-btn a'
            >
              {internship ? 'Internship' : 'Get Early Access'}
            </a>
          </div>
        </div>
      </div>
      {showEmailPopup && (
        <EmailPopup
          buttonName={popupContent.buttonText}
          hrefUrl={popupContent.href}
          onClose={handleEmailPopupClose}
        />
      )}
    </section>
  )
}

export default HeroComponent
