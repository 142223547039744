import React from 'react'
import './responsive_footer.css'
import {
  CDBFooter,
  CDBFooterLink,
  CDBBtn,
  CDBIcon,
  CDBContainer,
  CDBBox
} from 'cdbreact'
import Navbar from 'react-bootstrap/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faYoutube,
  faInstagram,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons'

const ResponsiveFooter = () => (
  <CDBFooter
    style={{
      width: '100%',
      margin: '50px 0 20px 0'
    }}
  >
    <CDBBox
      display='flex'
      justifyContent='between'
      className='flex-center-row'
      alignItems='center'
      style={{ width: '100%', padding: '0px 25px' }}
    >
      <CDBBox className='flex-center-row'>
        <Navbar.Brand
          href='https://terafac.live'
          className='text-dark-deco-none d-flex align-items-center flex-center-row'
        >
          <img
            alt='logo'
            src={require('../../assets/images/terafac-logo.png')}
            width='60px'
          />
          <span className='terafac-live mt-1'>
            Terafac <span style={{ fontWeight: 400 }}>Live</span>
          </span>
          {/* <span
            style={{
              fontSize: 36,
              fontWeight: '800',
              fontFamily: 'Futura, sans-serif',
              marginLeft: '5px'
            }}
          >
            Terafac <span style={{ fontWeight: 400 }}>Live</span>
          </span> */}
        </Navbar.Brand>
      </CDBBox>

      <CDBBox className='flex-center-row'>
        <h6 className='margins-0' style={{ marginLeft: 10, marginRight: 10 }}>
          <Navbar.Brand
            href='mailto:hello@terafac.live'
            className='text-dark-deco-none'
          >
            We'll love to hear from you !
          </Navbar.Brand>
        </h6>
      </CDBBox>
    </CDBBox>

    <CDBBox
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='row'
      className=''
      style={{ width: '100%', padding: '10px 30px' }}
    >
      <CDBBox display='flex' justifyContent={'center'} alignItems={'center'}>
        {/* <CDBBtn
          flat
          color='dark'
          className='p-2'
          href='https://www.youtube.com/@terafactechnologies3317'
        >
          <CDBIcon
            fab
            icon='youtube'
            href='https://www.youtube.com/@terafactechnologies3317'
          />
        </CDBBtn>
        <CDBBtn
          flat
          color='dark'
          className='mx-3 p-2'
          href='https://www.linkedin.com/company/terafac-technologies-pvt-ltd/'
        >
          <CDBIcon
            fab
            icon='linkedin'
            href='https://www.linkedin.com/company/terafac-technologies-pvt-ltd/'
          />
        </CDBBtn>
        <CDBBtn
          flat
          color='dark'
          className='p-2'
          href='https://www.instagram.com/terafactech/'
        >
          <CDBIcon
            fab
            icon='instagram'
            href='https://www.instagram.com/terafactech/'
          />
        </CDBBtn> */}
        <div className='icon-box'>
          <a
            href='https://www.youtube.com/@terafactechnologies3317'
            target='_blank'
            rel='noreferrer'
            className='social-media-icon'
          >
            <FontAwesomeIcon color='#25B3BC' icon={faYoutube} />
          </a>
          <a
            href='https://www.linkedin.com/company/terafac-technologies-pvt-ltd/'
            target='_blank'
            rel='noreferrer'
            className='social-media-icon'
          >
            <FontAwesomeIcon color='#25B3BC' icon={faLinkedin} />
          </a>
          <a
            href='https://www.instagram.com/terafactech/'
            target='_blank'
            rel='noreferrer'
            className='social-media-icon'
          >
            <FontAwesomeIcon color='#25B3BC' icon={faInstagram} />
          </a>
        </div>
      </CDBBox>
    </CDBBox>

    <CDBBox
      display='flex'
      alignItems='center'
      justifyContent={'center'}
      flexDirection='row'
    >
      <small className='copyright'>
        &copy;Terafac Live, 2023. All rights reserved.
      </small>
    </CDBBox>
  </CDBFooter>
)

export default ResponsiveFooter
