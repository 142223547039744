export const colors = {
    gray: "rgb(158, 154, 154)",
    lightGray: "rgb(209, 203, 203)",
    dimgray: "#696969",
    persianGreen: "#2A9D8F",
    darkModerateBlue: "#40768C",
    lightGrayishOrange: "#FDF1E7",
    white: "white",
    darkShadeMagenta: "#3E2943",
    darkMagenta: "hsl(287, 31%, 11%)",
    blackShade: "rgba(58, 55, 55, 0.7)",
    lightYellowShade: "#E5B94D",
    lightShadeRedOrange: "#E66B4C",
    lightShadeCyan: "#EBFAF8",
    darkCyanBlue: "#003D69",
    black: "#00000"
}