
import React, { useState } from 'react';
import './modal.css'

import { postEmail, saveEmailId, postEmailToDiscord } from '../../actions/Rating.action';
import { FiX } from "react-icons/fi";

import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'

const EmailModal= ({ show, email, isMoreJobs, setEmail, handleClose, handlePress, name }) => {

	

	const dispatch = useDispatch()

	function handleEmail(event) {
        setEmail(event.target.value)
    }

	function handleButton(e) {
		dispatch(saveEmailId(email))
		handlePress(email, isMoreJobs ? `` :`test`)

		// dispatch(postEmailToDiscord(emailValue))
		// e.preventDefault(); 
		// const baseUrl = e.currentTarget.getAttribute('href');
		// const fullUrl = `${baseUrl}?email=${encodeURIComponent(value)}`;
		// window.location.href = fullUrl;
	}

	return (
        <Modal
		    className='modal'
		    show={show}
		    onHide={handleClose}
		    animation={true}
		>
			{/* <h4>{JSON.stringify(savedEmail)}</h4> */}
		    <Modal.Header>
		    <div style={{ flex: 1, width: '100%', textAlign:'center'}}>{isMoreJobs ? "More Jobs Coming Soon!": "Start Evaluation"}</div>
		    	<FiX onClick={() => handleClose()} className='cross-modal mt-20' />
		    </Modal.Header>
		    <Modal.Body>
		        <p className="task-modal-content modal-content1">
		        	{isMoreJobs ? 
		        		`We are adding new skills and jobs every week, and we'll keep you updated about it. If you want us to prioritise any specific job, topic or training, just ask at hello@terafac.live.` :
		        		`Please provide your email here, we'll be sharing relevant training resources for training in ${name} after this evaluation.`
		        	}
		        </p>
		        <div className='email'>
		            <label className='first-name-label'></label>
		            <input
		                className='email-phone-input'
		                placeholder='Email'
		                typemail-phone="email"
						value={email}
		                onChange={handleEmail}
		            />
		        </div>
		        {/*<div className='email'>
		            <label className='first-name-label'></label>
		            <input
		                className='email-phone-input'
		                placeholder='Job'
		                onChange={handleJob}
		            />
		        </div>*/}
		    </Modal.Body>
		    <Modal.Footer className="modal-content modal-footer">
		        <button className="modal-btn ml-2 mr-2">
		            <button
		                className='a'
		                onClick={handleButton}
		            >
		                {isMoreJobs ? `Get Early Access` :`Proceed`}
		            </button>
		        </button>
		    </Modal.Footer>
		</Modal>
	    );
	};

export default EmailModal;

 