import React from 'react';
import "./job_portal.css";

import { Tabs, Tab, makeStyles } from "@material-ui/core";


/* Usage

import TabComponent from "../components/JobPortalComponents/TabComponent"

<TabComponent
    tabs={['Jobs', 'Training', 'Companies']}
    value={value}
    setValue={setValue}
/>

*/

const useStyles = makeStyles(theme => ({
    indicator: {
        backgroundColor: "#2A9D8F"
    },
    selected: {
        color: "#2A9D8F"
    }
}));



const TabComponent = ({ tabs, value, setValue }) => {

    const classes = useStyles();

    return (
        <div className="top-tabs">
            <Tabs
                classes={{
                    indicator: classes.indicator
                }}
                value={value}
                onChange={(event, newValue) => {
                    // console.log('yo', newValue)
                    return setValue(newValue);
                }}
            >
                {tabs.map((item, index) => (
                    <Tab
                        classes={{
                            selected: classes.selected
                        }}
                        label={item}
                        key={index}
                    />))}
            </Tabs>
        </div>
    );
};

export default TabComponent;



