import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import "../../styles/index.css";
import { useDispatch, useSelector } from 'react-redux'

const WebApplicationForm = (props) => {
    return (
        <section className='section2'>
            <form className='form'>
                <h4 className='application'>Please enter your details </h4>

                <div className='email-phonenumber'>
                    <div className='email'>
                        <label className='first-name-label'>First Name</label>
                        <input
                            className='email-phone-input'
                            placeholder='First Name'
                            type='text'
                            name='first-name'
                            value={props.firstName}
                            onChange={props.handleFirstName}
                        />
                    </div>
                    <div className='phone-number'>
                        <label className='first-name-label'>Last Name</label>
                        <input
                            className='email-phone-input'
                            placeholder='Last Name'
                            type="text"
                            name="last-name"
                            value={props.lastName}
                            onChange={props.handleLastName}
                        />
                    </div>
                </div>

                <div className='email-phonenumber'>
                    <div className='email'>
                        <label className='first-name-label'>
                            Email <span className="required-field" >*</span>
                        </label>
                        <input
                            className='email-phone-input'
                            placeholder='Email ( required )'
                            typemail-phone="email"
                            value={props.email}
                            onChange={props.handleEmail}
                        />

                    </div>
                    <div className='phone-number'>
                        <label className='first-name-label'>Phone Number</label>
                        <input
                            className='email-phone-input'
                            placeholder='Phone Number'
                            type="text"
                            name="phone-number"
                            value={props.number}
                            onChange={props.handleNumber}
                        />
                    </div>
                </div>
                <div className='join-question'>
                    <label className='first-name-label'>Why do you want to experience  this job?</label>
                    <textarea
                        className='jobdescription-textarea'
                        placeholder="Write your message"
                        cols="2"
                        rows="10"
                        value={props.yourMessage}
                        onChange={props.handleYourMessage}
                    >
                    </textarea>
                </div>
                <p className='experience-label'>What is your level of expertise?</p>
                <ul className='ul-of-experience'>
                    <label onClick={() => props.handleExperience("Beginner")} className='experience-label'>
                        <input
                            className='experience-radio'
                            type="radio"
                            name="experience"
                        />
                        Beginner
                    </label>
                    <label onClick={() => props.handleExperience("Intermediate")} className='experience-label'>
                        <input
                            className='experience-radio'
                            type="radio"
                            name="experience"
                        />
                        Intermediate
                    </label>
                    <label onClick={() => props.handleExperience("Advance")} className='experience-label'>
                        <input
                            className='experience-radio'
                            type="radio"
                            name="experience"
                        />
                        Advance
                    </label>
                </ul>
            </form>


            <Link
                className='apply-btn'
                exact to='/description'
                style={{ textDecoration: 'none' }}
                type="submit"
                onClick={() => { props.applyButton(props.item.job) }}
            >
                <div className='apply-btn-text a'>
                    Simulate
                </div>
            </Link>

        </section>
    )
}

export default WebApplicationForm;