import JobPortalScreen from './screens/JobPortalScreen';
import TaskScreen from './screens/TaskScreen';
import JobDescriptionScreen from './screens/JobDescriptionScreen';

import { Provider } from 'react-redux';

import {
  store,
  persistor
} from '../src/redux/store';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';


function App() {
  return (
    <Provider store={store} >
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        <Router>
          <Routes>
            <Route
              path='/'
              element={<JobPortalScreen />}
            />
            <Route
              path='/test'
              element={<TaskScreen />}
            />
            <Route
              path='/description'
              element={<JobDescriptionScreen />}
            />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
