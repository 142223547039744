import React from 'react';
import "./job_portal.css";

import Container from 'react-bootstrap/Container';

const TrainingList = ({ trainings, handleClick }) => {
    return (
         <Container fluid="xl" className="languages-main-div">
            {
                trainings.map((item,index) => (
                    <button
                        className='a'
                        onClick={() => handleClick(item.language)}
                        key={`training-${index}`}
                    >
                        <div className='language-box'>
                            <div className='language-image-div'>
                                <img className="language-image" src={item.image} />
                            </div>
                            <h6 style={{ color: "black" }}>{item.language}</h6>
                        </div>
                    </button>
                ))
            }
        </Container>
    );
};

export default TrainingList;



    