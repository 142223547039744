import React, {
    useState,
    useEffect,
    useLayoutEffect,
    useRef
} from 'react';
import '../styles/index.css';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import { BiArrowBack, BiCloudSnow } from 'react-icons/bi';
import { colors } from '../config/Theme';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';

import {
    fetchJobs,
    saveUserInfo,
    postInformationToDiscord,
    fetchSpecificJob,
    saveJobName,
    saveName,
    saveEmailId
} from '../actions/Rating.action';
import MarkdownComponent from '../components/MarkDownComponent/Markdown';
import ResponsiveFooter from '../components/ResponsiveFooter/ResponsiveFooter';

import MobileApplicationForm from '../components/ApplicationForm/MobileApplicationForm';
import WebApplicationForm from '../components/ApplicationForm/WebApplicationForm';
import SubmitModal from '../components/ModalComponents/SubmitModal';

function JobDescriptionScreen() {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const savedEmail = useSelector((state) => state.rating.popup_email)

    const [item, setItem] = useState([]);
    const [email, setEmail] = useState(savedEmail);
    const [name, setName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [number, setNumber] = useState("");
    const [yourMessage, setYourMessage] = useState("");
    const [experience, setExperience] = useState("");

    const [modal, setModal] = useState(false);
    const closeModal = (nav = false) => {
        if (nav) {
            navigate('/')
        }
        setModal(false);
    }
    const showModal = () => setModal(true);

    const location = useLocation();
    const childRef = useRef();


    const fetch_jobs = useSelector((state) => state.rating.specific_job)
    // console.log(" fetch_jobs : ", fetch_jobs)

    const job_id = useSelector((state) => state.rating.job_id)
    // console.log("job_id ", job_id)

    useLayoutEffect(() => {
        dispatch(fetchJobs())
        dispatch(fetchSpecificJob())
    }, [])

    useEffect(() => {
        // console.log(fetch_jobs)
        const jobData = fetch_jobs.filter((item) => {
            if (item.id == job_id) {
                return true
            }
        })
        if (jobData.length === 0) {
            // If jobData is empty, navigate to "/"

            navigate("/");
        } else {
            setItem(jobData[0]);
        }
    }, [])


    function handleEmail(event) {
        // console.log(event.target.value);
        setEmail(event.target.value)
    }

    function handleName(event) {
        // console.log(event.target.value);
        setName(event.target.value)
    }
    function handleLastName(event) {
        // console.log(event.target.value);
        setLastName(event.target.value)
        // console.log(" lastName : ", lastName)
    }
    function handleFirstName(event) {
        // console.log(event.target.value);
        setFirstName(event.target.value)
        // console.log(" first Name : ", firstName);

    }

    function handleNumber(event) {
        // console.log(event.target.value);
        setNumber(event.target.value)
    }

    function handleYourMessage(event) {
        // console.log(event.target.value);
        setYourMessage(event.target.value)
    }

    function handleExperience(event) {
        // console.log(event);
        setExperience(event)
    }

    function applyButton(job) {
        // console.log(" job Name : ", job)
        const name = `${firstName} ${lastName}`;
        dispatch(saveEmailId(email))
        dispatch(saveUserInfo(name, email, number, yourMessage, experience))
        dispatch(saveJobName(job))
        dispatch(postInformationToDiscord(name, email, number, yourMessage, experience, job))
        dispatch(saveName(name))

        showModal()
        // console.log("Apply button function is called");
    }

    const handleApplyNow = () => {
        childRef.current.applyButton();
    }

    /* Media Query section */
    const mobile = useMediaQuery({ query: '(max-width: 992px)' })

    return (
        <Container className="main-div">
            <div className="back-button-div">
                <Link
                    className='bk-btn'
                    to="/"
                >
                    <BiArrowBack style={{ fontSize: 25 }} />
                </Link>
            </div>
            
            {
                mobile ?
                    <div>
                        <div className='w-full h-full overflow-y-scroll scroll scroll-smooth scrollbar-hide'>
                            <h2 className='head-title'>{item.job}</h2>
                            <div className='benefits'>
                                <h5 className='benefits-text1'>Description</h5>
                                <MarkdownComponent
                                    className="custom-list"
                                    description={item.description}
                                />
                                <div className='companies-list-below-description'>
                                    {item?.companies_list?.map((url, index) =>

                                        <img src={url} key={index} alt='' className='' style={{ objectFit: 'contain', height: 100, width: 100, margin: 10}} />

                                    )}
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: 20 }}>
                            <MobileApplicationForm
                                email={email}
                                item={item}
                                firstName={firstName}
                                lastName={lastName}
                                number={number}
                                yourMessage={yourMessage}
                                experience={experience}
                                handleEmail={handleEmail}
                                handleFirstName={handleFirstName}
                                handleLastName={handleLastName}
                                handleNumber={handleNumber}
                                handleYourMessage={handleYourMessage}
                                handleExperience={handleExperience}
                                applyButton={applyButton}
                            />
                        </div>

                        <Link
                            style={{ color: colors.white }}
                            className='apply-now-btn a'
                            to="/description"
                            onClick={() => { applyButton(item.job) }}
                        >
                            Simulate
                        </Link>

                    </div>

                    :

                    <div className='web-container'>
                        <div className='head-section'>
                            <img src={item.image} alt='' style={{ objectFit: 'cover', height: "20vh", width: '100%' }} />

                        </div>
                        <div className='sections'>
                            <section className='section1'>
                                <div className='title-div'>

                                    <h4 className='title-div-text2'>{item.job}</h4>
                                </div>

                                <div className='paragraphs'>
                                    <MarkdownComponent
                                        className="custom-list"
                                        description={item.description} />

                                </div>
                                <div className='companies-list-below-description'>
                                    {item?.companies_list?.map((url) =>

                                        <img src={url} alt='' className='' style={{ objectFit: 'contain', height: 80, width: 100, margin: 10 }} />

                                    )}
                                </div>
                            </section>
                            <WebApplicationForm
                                email={email}
                                item={item}
                                firstName={firstName}
                                lastName={lastName}
                                number={number}
                                yourMessage={yourMessage}
                                experience={experience}
                                handleEmail={handleEmail}
                                handleFirstName={handleFirstName}
                                handleLastName={handleLastName}
                                handleNumber={handleNumber}
                                handleYourMessage={handleYourMessage}
                                handleExperience={handleExperience}
                                applyButton={applyButton}
                            />
                        </div>
                    </div>

            }
            
            <SubmitModal
                name={firstName}
                email={email}
                show={modal}
                handleClose={closeModal}
            />
            <ResponsiveFooter />
        </Container>
    )
}

export default JobDescriptionScreen;