import React, { useState, useEffect } from 'react'
import '../styles/index.css'

// import { useLocation } from 'react-router'
import Container from 'react-bootstrap/Container'
import { useNavigate, useLocation } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import queryString from 'query-string'

import {
  fetchJobs,
  filteredJobData,
  fetchLanguages,
  saveJobId,
  saveJobName,
  postEmail,
  fetchCompanies,
  fetchSpecificJob,
  postInformationToDiscord,
  saveEmailId
} from '../actions/Rating.action'

import HeroComponent from '../components/HeroComponent.js/HeroComponent'

import ResponsiveFooter from '../components/ResponsiveFooter/ResponsiveFooter'
import ResponsiveHeader from '../components/ResponsiveHeader/ResponsiveHeader'
import EmailModal from '../components/ModalComponents/EmailModal'

import JobList from '../components/JobPortalComponents/JobList'
import TrainingList from '../components/JobPortalComponents/TrainingList'
import CompanyList from '../components/JobPortalComponents/CompanyList'
import TabComponent from '../components/JobPortalComponents/TabComponent'

function JobPortalScreen () {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    // Make sure to call your fetch functions inside useEffect
    dispatch(fetchJobs()).then(result => dispatch(filteredJobData()));
    dispatch(fetchLanguages());
    dispatch(fetchCompanies());
    dispatch(fetchSpecificJob());
  
    const decodeBase64ToEmail = encodedEmail => {
      return atob(encodedEmail);
    };
  
    // Parse the search query
    const queryParams = queryString.parse(location.search);
    const encodedEmail = queryParams.email;
    console.log("encodedEmail", encodedEmail)
    if (encodedEmail) {
      const decodedEmail = decodeBase64ToEmail(encodedEmail);
      console.log("decodedEmail", decodedEmail)
      dispatch(saveEmailId(decodedEmail));
    }
  }, [dispatch, location]); // location is now a dependency of useEffect

  const trainings = useSelector(state => state.rating.languages)
  const companies = useSelector(state => state.rating.comapanies)
  const jobs = useSelector(state => state.rating.specific_job)
  const savedEmail = useSelector(state => state.rating.popup_email)

//   const location = useLocation()
  const isInternship = location.search.includes('internship')

  const [value, setValue] = React.useState(0)

  const [modal, setModal] = useState(false)
  const closeModal = () => setModal(false)
  const showModal = () => setModal(true)

  const [tab, setTab] = React.useState(0)
  const [training, setTraining] = useState('')
  const [email, setEmail] = useState(savedEmail)
  const [isMoreJobs, setIsMoreJobs] = useState(false)
  const [job, setJob] = useState('')

  function handlePress (email, to) {
    dispatch(postEmail(email))
    dispatch(postInformationToDiscord('', email, '', '', '', job))
    if (to) {
      navigate(to)
    }
    closeModal()
  }

  function handleJob (jobId, jobName) {
    dispatch(saveJobId(jobId))
    setJob(jobName)
    dispatch(saveJobName(jobName))
  }

  return (
    <Container className='job-portal'>
      <ResponsiveHeader />
      <HeroComponent internship={isInternship} />
      <TabComponent
        tabs={['Jobs', 'Training', 'Internship']}
        value={tab}
        setValue={setTab}
      />

      {tab == 0 && (
        <JobList
          jobs={jobs}
          handleClick={handleJob}
          showModal={() => {
            setIsMoreJobs(true)
            showModal()
          }}
        />
      )}
      {tab == 1 && (
        <TrainingList
          trainings={trainings}
          handleClick={item => {
            setTraining(item)
            showModal()
          }}
        />
      )}
      {tab == 2 && <CompanyList companies={companies} />}

      <EmailModal
        show={modal}
        email={email}
        setEmail={setEmail}
        handleShow={showModal}
        handleClose={() => {
          setIsMoreJobs(false)
          closeModal()
        }}
        handlePress={handlePress}
        isMoreJobs={isMoreJobs}
        name={training}
      />
      <ResponsiveFooter />
    </Container>
  )
}

export default JobPortalScreen
