const initialState = {
  list: [],
  users_rating: [],
  email_response_list: [],
  interview_detail: [],
  get_pseudoname: '',
  fetch_task: [],
  fetch_jobs: [],
  user_id: null,
  user_email: '',
  filter_id: null,
  build_submit_list: {},
  english_test: [],
  aptitude_test: [],
  english_test_build_submit_list: {},
  aptitude_test_build_submit_list: {},
  job_filters: [],
  languages: [],
  language_task: [],
  comapanies: [],
  job_id: null,
  email: '',
  specific_job: [],
  job_name: '',
  user_name: '',
  popup_email: ""
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USERS_RATING':
      return {
        ...state,
        users_rating: action.payload
      }

    case 'POST_EMAIL':
      return {
        ...state,
        // get_pseudoname: action.payload.emailResponse.pseudoname,
        // user_id: action.payload.emailResponse.user_id,
        user_email: action.payload.email,
        email: action.payload.email
      }

    case 'SAVE_USER_EMAIL':
      return {
        ...state,
        email: action.payload
      }

    case 'FETCH_TASK':
      return {
        ...state,
        fetch_task: action.payload
      }
    case 'SPECIFIC_JOB':
      return {
        ...state,
        specific_job: action.payload
      }

    case 'FETCH_JOBS':
      return {
        ...state,
        fetch_jobs: action.payload
      }

    case 'FILTERED_JOB_DATA': {
      const filtered_job_data = action.payload.jobData.filter(item => {
        if (action.payload.filter == undefined) {
          return true
        } else {
          if (action.payload.filter.filter_name == 'All') {
            return true
          } else {
            const item_data = `${item.job.toUpperCase()})`
            const text_data = action.payload.filter.filter_name.toUpperCase()
            return item_data.indexOf(text_data) > -1
          }
        }
      })

      let filter_id
      if (action.payload.filter == undefined) {
        filter_id = null
      } else {
        filter_id = action.payload.filter.id
      }

      return {
        ...state,
        filtered_job_data: filtered_job_data,
        filter_id
      }
    }

    case 'SEARCHED_JOB_DATA': {
      const filtered_job_data = action.payload.jobData.filter(item => {
        if (action.payload.filter == undefined) {
          return true
        } else {
          if (action.payload.filter == 'All') {
            return true
          } else {
            const item_data = `${item.job.toUpperCase()})`
            const text_data = action.payload.filter.toUpperCase()
            return item_data.indexOf(text_data) > -1
          }
        }
      })

      return {
        ...state,
        filtered_job_data: filtered_job_data
      }
    }

    case 'BUILD_SUBMIT_LIST': {
      state.build_submit_list[action.payload.id] = action.payload.answer

      return {
        ...state,
        build_submit_list: state.build_submit_list
      }
    }

    case 'ENGLISH_TEST':
      return {
        ...state,
        english_test: action.payload
      }

    case 'APTITUDE_TEST':
      return {
        ...state,
        aptitude_test: action.payload
      }

    case 'ENGLISH_TEST_BUILD_SUBMIT_LIST': {
      state.english_test_build_submit_list[action.payload.id] =
        action.payload.answer
      return {
        ...state,
        english_test_build_submit_list: state.english_test_build_submit_list
      }
    }
    case 'APTITUDE_TEST_BUILD_SUBMIT_LIST': {
      state.aptitude_test_build_submit_list[action.payload.id] =
        action.payload.answer
      return {
        ...state,
        aptitude_test_build_submit_list: state.aptitude_test_build_submit_list
      }
    }

    case 'FETCH_JOB_FILTERS':
      return {
        ...state,
        job_filters: action.payload
      }

    case 'LANGUAGES':
      return {
        ...state,
        languages: action.payload
      }
    case 'COMPANIES':
      return {
        ...state,
        comapanies: action.payload
      }
    case 'LANGUAGE_TASK':
      return {
        ...state,
        language_task: action.payload
      }

    case 'SAVE_JOB_ID':
      return {
        ...state,
        job_id: action.payload
      }

    case 'SAVE_JOB_NAME':
      return {
        ...state,
        job_name: action.payload
      }
    case 'SAVE_USER_NAME':
      return {
        ...state,
        user_name: action.payload
      }
    case 'SAVE_USER_INFO':
      return {
        ...state,
        get_pseudoname: action.payload.apiResponse.pseudoname,
        user_id: action.payload.apiResponse.user_id,
        user_email: action.payload.email
      }

    case 'SAVE_EMAIL_ID':
      return {
        ...state,
        popup_email: action.payload
      }
    default:
      return state
  }
}
export default reducer
